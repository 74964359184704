import { AnyAction, Dispatch } from "redux";
import { inject } from "../../common/IocModule";
import { ContinuationState, RootState } from "../../common/RootState";
import { CommonActions } from "../../common/Actions";
import { PoolDropClient } from "../../services/PoolDropClient";
import { PoolDrop } from "../../common/Types";

export interface ConfirmationProps extends ContinuationState {
    amount: string;
    poolDrop: PoolDrop,
}

export interface ConfirmationDispatch {
    onLoad: (requestId: string) => Promise<void>;
    onRefresh: (props: ConfirmationProps) => Promise<void>
}

function mapStateToProps(state: RootState): ConfirmationProps {
    return {
        ...state.ui.continuation,
        amount: state.ui.continuation.amount,
        poolDrop: state.data.currentPoolDrop,
    };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    onLoad: async (requestId: string) => {
        const client = inject<PoolDropClient>(PoolDropClient);            
        await client.processRequest(dispatch, requestId);
    },
    onRefresh: async (props:ConfirmationProps) => {
        // const client = inject<PoolDropClient>(PoolDropClient);            
        // await client.refreshStakeEvents(dispatch, [props.stakeEvent!]);
    }
} as ConfirmationDispatch);

const defaultconfirmationState = {
    action: '' as any,
    amount: '',
} as ContinuationState;

function reduce(state: ContinuationState = defaultconfirmationState, action: AnyAction) {    
    switch(action.type) {
        case CommonActions.CONTINUATION_DATA_RECEIVED:
            const {action: continuationAction, amount, mainTxId} = action.payload;
            return {...state, error: undefined, action: continuationAction,
                amount: amount, mainTxId};
        case CommonActions.CONTINUATION_DATA_FAILED:
            return {...state, error: action.payload.message};
        default:
        return state;
    }
}

export const ConfirmTxn = ({
    mapDispatchToProps,
    mapStateToProps,
    reduce
});