import React from 'react';
import { connect } from 'react-redux';
import { ConfirmationDispatch, ConfirmationProps, ConfirmTxn } from './ConfirmTxn';
import {
    Page, TransactionContinuation, PageTopPart, ThemedText, Row,
    //@ts-ignore
} from 'unifyre-web-components';
import { Utils } from '../../common/Utils';
import { formatter } from '../../services/RatesService';

function ConfirmationComponent(props: ConfirmationProps&ConfirmationDispatch) {
    const successMsg = (
        `You have signed a pooldrop ${
                    formatter.format(props.amount, false)}`);
    return (
        <Page>
            <PageTopPart>
                <Row centered><ThemedText.H2>{`Pool Drop`}</ThemedText.H2></Row>
            </PageTopPart>
            <TransactionContinuation
                requestId={Utils.getQueryparam('continuation')}
                network={props.poolDrop.network}
                onLoad={props.onLoad}
                txIds={props.mainTxId? [props.mainTxId] : []}
                okButtonText={'Continue'}
                okButtonUrl={`/info/${props.poolDrop.id}`}
                backButtonUrl={`/info/${props.poolDrop.id}`}
                onRefresh={() => props.onRefresh(props)}
                transactionStatus={''}
                successMessage={successMsg}
                error={props.error}
            />
        </Page>
    );
}

export const ConfirmTxnContainer = connect(
    ConfirmTxn.mapStateToProps, ConfirmTxn.mapDispatchToProps)(ConfirmationComponent);
