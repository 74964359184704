import { ValidationUtils } from "ferrum-plumbing";


export class Utils {
    static getQueryparams(): any {
        const rv: any = {};
        const queryParams = (window.location.href.split('?')[1] || '').split('&').map(p => p.split('='));
        queryParams.forEach(p => rv[p[0]] = p[1]);
        return rv;
    }

    static getQueryparam(param: string): string | undefined {
        const queryParams = (window.location.href.split('?')[1] || '').split('&').map(p => p.split('='));
        return (queryParams.find(p => p[0] === param) || [])[1];
    }

    static getRoute(subRoute: string) {
        let base = window.location.href.split('?')[0];
        if (!base.endsWith('/')) {
            base = base + '/';
        }
        return base + subRoute;
    }

    static platform(): 'desktop' | 'iOS' | 'android' {
        var iOs = /Phone|iPad|iPod/i.test(navigator.userAgent);
        var android = /Android/i.test(navigator.userAgent);
        if (iOs) { return 'iOS'; };
        if (android) { return 'android'; };
        return 'desktop';
    }

    static shorten(s: string) {
        if (s.length <= 25) { return s; }
        return `${s.substr(0, 10)}...${s.substr(s.length - 10)}`;
    }

    static linkForAddress(network: string, addr: string) {
        return  (network === 'RINKEBY') ?
            `https://rinkeby.etherscan.io/address/${addr}` :
            `https://etherscan.io/address/${addr}`;
    }

    static linkForTransaction(network: string, tid: string) {
        return  (network === 'RINKEBY') ?
            `https://rinkeby.etherscan.io/tx/${tid}` :
            `https://etherscan.io/tx/${tid}`;
    }

    static validateMultipleEmailsFromArray (emails: string[]) {
    
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        const invalidEmails: string[] = [];
        const validEmails: string[] = [];
        
        for (var i = 0; i < emails.length; i++) {
            // Trim whitespaces from email address
            emails[i] = emails[i].trim().toLowerCase();
            
            // Check email against our regex to determine if email is valid
            if( emails[i] == "" || ! regex.test(emails[i])){
                invalidEmails.push(emails[i]);
            }else{
                validEmails.push(emails[i])
            }
        }
        return {emails,invalidEmails,validEmails, total: ''};
    }

    static validateMultipleEmails (emailInput?: string) {
        // Get value on emails input as a string
        if (!emailInput) { return undefined }
        // Split string by comma into an array
        const emails = emailInput!.split(",");
        return Utils.validateMultipleEmailsFromArray(emails);
    }     

    static validateMultipleAmounts(amounts: string[]): string {
        return amounts.map(a => {
            ValidationUtils.isTrue(!!a.match(/^[1-9]\d*(\.\d+)?$/), `Provided amount "${a}" is not a number`);
            return new Big(a);
        }).reduce((s, v) => s = s.add(v), new Big(0)).toString();
    }

    static validateMultipleEmailsDifferentValues(input?: string) {
        // Get value on emails input as a string
        if (!input) { return undefined }
        const lines = input!.replace(/ /g, '').split("\n");
        const parts = lines.map(i => i.split(',', 1)).map(p => ({email: p[0], amount: p[1]}));
        const rv = Utils.validateMultipleEmailsFromArray(parts.map(p => p.email));
        const total = Utils.validateMultipleAmounts(parts.map(p => p.amount));
        return {...rv, total};
    }
}